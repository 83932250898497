@import url("//hello.myfonts.net/count/3e7523");

@font-face {
	font-family: "Axiforma-Book";
	src: url('./fonts/AxiformaBook/font.woff2') format('woff2'), url('./fonts/AxiformaBook/font.woff') format('woff');
}

@font-face {
	font-family: "Axiforma-Medium";
	src: url('./fonts/AxiformaMedium/font.woff2') format('woff2'), url('./fonts/AxiformaMedium/font.woff') format('woff');
}

@font-face {
	font-family: "Axiforma-SemiBold";
	src: url('./fonts/AxiformaSemiBold/font.woff2') format('woff2'), url('./fonts/AxiformaSemiBold/font.woff') format('woff');
}
