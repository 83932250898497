$tablet-width: 768px;
$desktop-width: 1024px;
$mid-desktop-width: 1200px;

$desktop-tall-height: 1012px;
$desktop-short-min-height: 598px;
$desktop-short-max-height: 702px;

@mixin tablet {
	@media (min-width: #{$tablet-width}) {
	  @content;
	}
}

@mixin desktop {
	@media (min-width: #{$desktop-width}) {
	  @content;
	}
}

@mixin tall-desktop {
	@media (min-height: #{$desktop-tall-height}) and (min-width: #{$desktop-width}) and (orientation: portrait) {
		@content;
	}
}

@mixin short-desktop {
	@media (min-width: #{$desktop-width}) and (min-height: #{$desktop-short-min-height}) and (max-height: #{$desktop-short-max-height})  {
		@content;
	}
}
