@import '_mixins';
@import '_fonts';

* {
	margin: 0;
	padding: 0;
}

html, body {
	background-color: #192939;
}

#wrapper {
	width: 100%;
	height: 100vh;
	overflow: hidden;

	article {
		position: relative;

		section.landing {
			display: flex;
			align-content: center;
			justify-content: center;

			.content {
				width: 320px;
				display: flex;
				flex-direction: column;
				height: 100vh;

				@include tablet {
					width: 600px;
				}

				.content-block {
					display: flex;
					flex: 1;
					align-items: center;
					justify-content: center;
				}

				header {
					h1 {
						text-align: center;
						text-transform: uppercase;
						color: white;
						font-family: 'Axiforma-Semibold', sans-serif;
						letter-spacing: 6px;
						font-size: 24px;
					}
				}

				.logo {
					position: relative;

					.logo-lockup {
						position: absolute;
						width: 103px;
						height: 103px;
						top: 50%;
						left: 50%;

						img {
							width: 100%;
							height: 100%;
							transform: translate(-50%,-50%);
						}
					}
					
					.logo-element {
						width: 150px;
						height: 150px;
						border-radius: 50%;
						// box-shadow: 0 0 90px 2px #70BCFF, 0 0 10px 2px #70BCFF;
						animation: glow 2s infinite alternate;


						@include tablet {
							width: 250px;
							height: 250px;
						}

						@keyframes glow {
							from {
								box-shadow: 0 0 90px 2px #70BCFF, 0 0 10px 2px #70BCFF;
							}
							to {
								box-shadow: 0 0 90px 2px #184f80, 0 0 10px 2px #083f6e;
							}
						}
					}
				}

				nav {
					display: flex;
					align-items: center;
					justify-content: center;

					ul {
						list-style: none;

						li {
							// display: block;
							margin-bottom: 10px;
							text-align: center;
							flex: 1;

							@include tablet {
								margin: 0 10px;
								display: inline-block;
							}

							a {
								color: white;
								transition: all 0.4s;
								text-transform: uppercase;
								cursor: pointer;
								text-decoration: none;
								font-family: 'Axiforma-Medium', sans-serif;

								&:hover {
									color: rgb(5, 197, 255);
									text-decoration: underline;
								}
							}

						}
					}
				}
			}
			
		}

		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba(0,0,0,0.5);
			width: 100%;
			height: 100vh;
			backdrop-filter: blur(5px);
			
			opacity: 0;
			display: none;

			&.open {
				display: flex;
				// opacity: 100;
			}

			.close-icon {
				position: absolute;
				display: block;
				top: 20px;
				right: 20px;
				width: 30px;
				height: 30px;
				text-indent: -9000px;
				background:none;
				border: none;
				background-repeat: no-repeat;
				background-image: url(/wp-content/themes/GreatMachine2021-placeholder/images/CloseIcon.svg);
				background-size: 100% 100%;
				cursor: pointer;
			}

			section.about {
				align-items: center;
				justify-content: center;
	
				.content {
					// background-color: white;
					width: 80%;
					max-width: 800px;
					padding: 40px;
					color: white;
					font-family: "Axiforma-Book", sans-serif;
					// text-align: center;

					@include tablet {
						width: 70%;
						padding: 50px;
					}

					h2 {
						font-family: "Axiforma-Bold", sans-serif;
						font-weight: 700;
						margin-bottom: 40px;
						font-size: 20px;
						line-height: 28px;
						text-transform: uppercase;

						@include tablet {
							line-height: 38px;
							font-size: 26px;
						}
					}

					h3 {
						font-family: "Axiforma-Bold", sans-serif;
						font-size: 18px;
						text-transform: uppercase;
						margin-bottom: 6px;

						@include tablet {
							font-size: 24px;
						}
					}
	
					p {
						margin-bottom: 10px;
						line-height: 24px;
						font-size: 14px;
						margin-bottom: 20px;

						@include tablet {
							line-height: 36px;
							font-size: 22px;
							padding-right: 60px;
							
						}
					}

					p.we-get-it {
						font-family: "Axiforma-Bold", sans-serif;
						font-weight: 700;
						line-height: 24px;
						font-size: 20px;
						text-transform: uppercase;
						margin-top: 30px;

						@include tablet {
							line-height: 36px;
							font-size: 26px;
							margin-top: 40px;
							
						}
					}
				}
			}
	
			section.reel {
				align-items: center;
				justify-content: center;
	
				.content {
					position: relative;
					width: 100%;
					padding-bottom: 56.25%; /* 16:9 */
					height: 0;

					@include desktop {
						width: 80%;
					}
	
					iframe {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}
			}
	
			section.generator {
				align-items: center;
				justify-content: center;
	
				.content {
					position: relative;
					width: 100%;
					padding-bottom: 56.25%; /* 16:9 */
					height: 0;

					@include desktop {
						width: 80%;
					}
	
					iframe {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}
			}
	
			section.contact {
				align-items: center;
				justify-content: center;
				
				.content {
					background-color: #F3C9BA;
					width: 75%;
					height: 75%;

					.wrapper {
						padding: 40px 80px;

						header {
							h2 {
								font-family: "Axiforma-Semibold", sans-serif;
								text-transform: uppercase;
							}
						}

						.wpforms-container {
							margin-top: 20px;

							.wpforms-field-container {

								.wpforms-field {
									margin-bottom: 30px;

									label {
										display: block;
										margin-bottom: 15px;
										font-family: "Axiforma-Medium", sans-serif;
									}

									input {
										border: none;
										height: 30px;
										width: 345px;
										padding: 10px;
										font-family: "Axiforma-Book", sans-serif;
									}
								}

								.message-field {
									textarea {
										border: none;
										width: 100%;
										height: 100px;
										padding: 10px;
										font-family: "Axiforma-Book", sans-serif;
									}
								}
							}

							.wpforms-submit-container {
								.wpforms-submit {
									border: none;
									background: none;
									background-color: #192939;
									color: white;
									height: 44px;
									width: 115px;
									border-radius: 22px;
									text-transform: uppercase;
									cursor: pointer;
									transition: all 0.4s;
									font-family: "Axiforma-Semibold", sans-serif;
									font-size: 16px;

									&:hover {
										background-color: #70BCFF;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}